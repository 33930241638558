<template>
  <b-row>
    <b-col md="12">
      <b-row>
        <b-col md="12">

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="float-right mb-1 ml-2"
            onclick="history.back()"
          >
            <feather-icon icon="ArrowLeftCircleIcon" />
            Retour
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-modal.validation-step
            v-b-tooltip.hover.bottom="'Validation votre étape'"
            variant="success"
            size="sm"
            :disabled="processCertificateVerification.status === 'Validé'"
            class="float-right mb-1"
          >
            <feather-icon icon="CheckCircleIcon" />
            Valider cette étape
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="5">
          <b-row>
            <b-col md="12">
              <b-card no-body>
                <b-card-body>
                  <b-list-group>
                    <b-list-group-item
                      class="d-flex justify-content-between align-items-center"
                      active
                    >
                      <span>Informations sur la demande</span>

                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between align-items-center">
                      <span>Document demandé</span>
                      {{ requestCertificate.type_certificat.name }}

                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between align-items-center">
                      <span>Etat de la demande</span>
                      <b-badge
                        pill
                        :variant="`${resolveRequestCertificateStatusVariant(requestCertificate.status)}`"
                      >
                        {{ requestCertificate.status }}
                      </b-badge>
                    </b-list-group-item>

                    <b-list-group-item class="d-flex justify-content-between align-items-center">
                      <span>Date de la demande</span>
                      {{ requestCertificate.created_at }}

                    </b-list-group-item>

                  </b-list-group>
                </b-card-body>
              </b-card>
            </b-col>
            <b-col md="12">
              <b-card no-body>
                <b-card-body>
                  <b-list-group>
                    <b-list-group-item
                      class="d-flex justify-content-between align-items-center"
                      active
                    >
                      <span>Informations de l'impétrant</span>
                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between align-items-center">
                      <span>Nom de l'impétrant</span>
                      {{ requestCertificate.demandeur.name }}

                    </b-list-group-item>

                    <b-list-group-item class="d-flex justify-content-between align-items-center">
                      <span>NPI</span>
                      {{ requestCertificate.demandeur.npi }}

                    </b-list-group-item>

                    <b-list-group-item class="d-flex justify-content-between align-items-center">
                      <span>Numéro de téléphone</span>
                      {{ requestCertificate.demandeur.phonenumber }}

                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between align-items-center">
                      <span>Adresse e-mail</span>
                      {{ requestCertificate.demandeur.email }}
                    </b-list-group-item>
                  </b-list-group>
                </b-card-body>
              </b-card>
            </b-col>

          </b-row>
        </b-col>
        <b-col md="7">
          <b-row>
            <b-col md="12">
              <b-card title="Suivi du processus de validation">
                <ul class="progress_bar pb-5">
                  <li
                    v-b-tooltip.hover.bottom="`Date : ${requestCertificate.created_at}`"
                    :class="requestCertificate.status === 'Soumis' || requestCertificate.status === 'En cours' || requestCertificate.status === 'Traité' || requestCertificate.status === 'Rejeté' ? 'active' : ''"
                  >
                    Soumise
                  </li>
                  <li
                    v-b-tooltip.hover.bottom="`Date : ${requestCertificate.date_encours}`"
                    :class="requestCertificate.status === 'En cours' || requestCertificate.status === 'Traité' || requestCertificate.status === 'Rejeté' ? 'active' : ''"
                  >
                    En cours
                  </li>
                  <li
                    v-b-tooltip.hover.bottom="`Date : ${processCertificateVerification.date_validee}`"
                    :class="processCertificateVerification.status === 'Validé' || requestCertificate.status === 'Traité' ? 'active' : ''"
                  >
                    Vérification
                  </li>
                  <li
                    v-b-tooltip.hover.bottom="`Date : ${processCertificateSignature.date_validee}`"
                    :class="processCertificateSignature.status === 'Validé' || requestCertificate.status === 'Traité' ? 'active' : ''"
                  >
                    Signature
                  </li>
                </ul>
                <b-card-body />
              </b-card>
            </b-col>
            <b-col md="12">
              <b-card title="Pièces fournies">
                <b-card-body>
                  <b-table
                    striped
                    hover
                    size="sm"
                    responsive
                    :busy="loader"
                    class="position-relative"
                    :items="items"
                    :fields="fields"
                  >
                    <template #table-busy>
                      <div class="d-flex justify-content-center mb-3">
                        <b-spinner
                          style="width: 2rem; height: 2rem;"
                          variant="primary"
                          label="Large Spinner"
                          type="grow"
                          class="mt-2"
                        />
                        <span class="sr-only">Chargement en cours...</span>
                      </div>
                    </template>
                    <template #cell(status)="data">
                      <b-badge
                        pill
                        :variant="`light-${resolvePieceStatusVariant(data.item.status)}`"
                      >
                        {{ data.item.status }}
                      </b-badge>
                    </template>
                    <template #cell(actions)="data">
                      <div class="text-center">
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          v-b-tooltip.hover.bottom="'Voir la pièce'"
                          v-b-modal.modal-display
                          variant="dark"
                          size="sm"
                          class="btn-icon rounded-circle ml-1"
                          @click.prevent="getPdfFile(data.item)"
                        >
                          <feather-icon icon="EyeIcon" />
                        </b-button>

                      </div>
                    </template>

                  </b-table>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </b-col>

      </b-row>
    </b-col>
    <b-modal
      id="modal-display"
      ref="display-modal"
      size="xl"
      button-size="sm"
      title="Pièce jointe"
      ok-only
      ok-title="Fermer"
    >
      <b-card style="height: 500px;">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          v-b-modal.rejet
          size="sm"
          class="mb-1 float-right ml-1"
          variant="danger"
          @click="getState('Rejeté')"
        >
          Rejeter
          <feather-icon icon="AlertTriangleIcon" />
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          v-b-modal.validation
          size="sm"
          class="mb-1 float-right"
          variant="success"
          @click="getState('Validé')"
        >
          Valider
          <feather-icon icon="CheckCircleIcon" />

        </b-button>

        <iframe
          style="height: 489px; width: 100%;"
          :src="PDFURL"
        />
      </b-card>
    </b-modal>
    <b-modal
      id="validation"
      ref="my-modal"
      cancel-title="Non"
      ok-title="Oui"
      title="Validation de pièce"
      modal-class="modal-success"
      ok-variant="success"
      button-size="sm"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      @ok="handleValidateOk"
    >
      <form @submit.prevent="handleValidateSubmitModal">
        <h6>Etes-vous sûr de vouloir valider cette pièce?</h6>

      </form>
    </b-modal>
    <b-modal
      id="validation-step"
      ref="my-modal"
      cancel-title="Non"
      ok-title="Oui"
      title="Validation"
      modal-class="modal-success"
      ok-variant="success"
      button-size="sm"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      @ok="handleValidateProcessOk"
    >
      <form @submit.prevent="handleValidateProcessSubmitModal">
        <h6>Etes-vous sûr de vouloir valider cette étape de vérification?</h6>

      </form>
    </b-modal>
    <b-modal
      id="rejet"
      ref="my-modal"
      cancel-title="Non"
      ok-title="Oui"
      title="Rejet de pièce"
      modal-class="modal-danger"
      ok-variant="danger"
      button-size="sm"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      @ok="handleRejeterOk"
    >
      <validation-observer ref="rejetValidationRules">
        <b-form @submit.prevent="handleRejeterSubmitModal">
          <h6>Etes-vous sûr de vouloir rejeter cette pièce?</h6>

          <b-form-group
            v-if="user.email===null"
            for="email-default"
            label="Email"
          >
            <validation-provider
              #default="{ errors }"
              name="email"
              rules="required|email"
            >
              <b-form-input
                id="email-default"
                v-model="form.email"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>

            </validation-provider>
          </b-form-group>
          <b-form-group
            for="textarea-default"
            label="Commentaire"
          >
            <validation-provider
              #default="{ errors }"
              name="commentaire "
              rules="required"
            >
              <b-form-textarea
                id="textarea-default"
                v-model="form.commentaire"
                :state="errors.length > 0 ? false : null"
                placeholder="Commentaire"
                rows="3"
              />
              <small class="text-danger">{{ errors[0] }}</small>

            </validation-provider>
          </b-form-group>

        </b-form>
      </validation-observer>
    </b-modal>
  </b-row>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
} from '@validations'
import {
  onBeforeMount, reactive, ref,
} from '@vue/composition-api'
import {
  BRow, BCol, BCard, BBadge, BTable, BFormInput, BFormGroup, BButton, BCardBody, BSpinner, VBTooltip, BListGroup, BListGroupItem, BModal, BFormTextarea, BForm,
} from 'bootstrap-vue'

// eslint-disable-next-line import/no-cycle
import useRequestCertificates from '@/services/requestCertificate/requestCertificate'
// eslint-disable-next-line import/no-cycle
import useAttachements from '@/services/agent/attachementService'
// eslint-disable-next-line import/no-cycle
import useProcessCertificates from '@/services/agent/processCertificateService'
// eslint-disable-next-line import/no-cycle
import axiosClient from '@/helpers/axios'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BButton,
    BCardBody,
    BBadge,
    BFormTextarea,
    BFormInput,
    BSpinner,
    BListGroup,
    BFormGroup,
    BListGroupItem,
    BModal,
    BForm,
    ValidationProvider,
    ValidationObserver,

  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  setup() {
    const {
      attachements, attachementSuccess, changerStatusAttachement,
    } = useAttachements()
    const {
      validationProcessCertificate, findProcessCertificateVerification, findProcessCertificateSignature, processCertificateVerification, processCertificateSignature, processCertificateSuccess,
    } = useProcessCertificates()
    const {
      requestCertificate, loader, findRequestCertificateById,
    } = useRequestCertificates()
    const fields = [
      { key: 'document_type.label', label: 'Pièce', sortable: true },
      { key: 'status', label: 'Etat', sortable: true },
      { key: 'numero_piece', label: 'N°Pièce', sortable: true },
      { key: 'commentaire', label: 'Commentaire', sortable: true },
      { key: 'actions', label: 'Actions', sortable: true },
    ]
    const form = reactive({
      status: '',
      commentaire: '',
      email: null,
      id: null,
    })
    const items = ref([])
    const user = ref([])
    const clearPiece = () => {
      form.status = ''
      form.email = null
      form.commentaire = ''
      form.id = null
    }
    const getUser = async () => {
      const response = await axiosClient.get('/user')
      user.value = response.data
    }

    onBeforeMount(async () => {
      await findRequestCertificateById()
      await findProcessCertificateVerification()
      await findProcessCertificateSignature()
      await getUser()
      items.value = requestCertificate.value.attachements
      console.clear()
    })
    const resolvePieceStatusVariant = status => {
      if (status === 'Envoyé') return 'warning'
      if (status === 'Validé') return 'success'
      if (status === 'Rejeté') return 'danger'
      return 'primary'
    }
    const resolveRequestCertificateStatusVariant = status => {
      if (status === 'Soumis') return 'primary'
      if (status === 'En cours') return 'warning'
      if (status === 'Traité') return 'success'
      if (status === 'Rejeté') return 'danger'
      return 'primary'
    }
    const PDFURL = ref('')
    const getPdfFile = item => {
      PDFURL.value = ''
      PDFURL.value = item.pdf_url
      form.id = item.id
    }
    // Obtenir une piece
    const getState = state => {
      form.status = state
    }

    const changerStatusPiece = async () => {
      await changerStatusAttachement({ ...form })
      await findRequestCertificateById()
      await getUser()

      if (attachementSuccess.value === true) {
        items.value = attachements.value
        clearPiece()
        console.clear()
      }
    }
    const changerStatusProcess = async () => {
      form.status = 'Validé'
      form.id = processCertificateVerification.value.id

      await validationProcessCertificate({ ...form })

      if (processCertificateSuccess.value === true) {
        clearPiece()
        // console.clear()
      }
      await findRequestCertificateById()
      await findProcessCertificateVerification()
      await findProcessCertificateSignature()
    }
    const formatter = value => value.toUpperCase()
    return {
      requestCertificate,
      formatter,
      changerStatusPiece,
      getState,
      loader,
      PDFURL,
      clearPiece,
      getPdfFile,
      processCertificateVerification,
      processCertificateSignature,
      resolveRequestCertificateStatusVariant,
      resolvePieceStatusVariant,
      changerStatusProcess,
      fields,
      required,
      user,
      items,
      form,
    }
  },
  methods: {
    handleRejeterOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleRejeterSubmitModal()
    },
    handleRejeterSubmitModal() {
      // Exit when the form isn't valid
      this.$refs.rejetValidationRules.validate().then(async success => {
        if (success) {
          await this.changerStatusPiece()
          this.clearPiece()
          this.$nextTick(() => {
            this.$refs['my-modal'].toggle('#toggle-btn')
            this.$refs['display-modal'].toggle('#toggle-btn')
          })
          // Hide the modal manually
        }
      })
    },
    handleValidateOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleValidateSubmitModal()
    },
    async handleValidateSubmitModal() {
      // Exit when the form isn't valid

      await this.changerStatusPiece()
      this.clearPiece()
      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs['my-modal'].toggle('#toggle-btn')
        this.$refs['display-modal'].toggle('#toggle-btn')
      })
    },
    handleValidateProcessOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleValidateProcessSubmitModal()
    },
    async handleValidateProcessSubmitModal() {
      // Exit when the form isn't valid

      await this.changerStatusProcess()
      this.clearPiece()
      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs['my-modal'].toggle('#toggle-btn')
      })
    },

  },

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style scoped>
.progress_bar {

  display: flex;
  counter-reset: circle 0;
}

.progress_bar li {
  list-style: none;
  margin-right: 55px;
  counter-increment: circle 1;
  display: flex;
  position: relative;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.progress_bar li::before {
  content: counter(circle);
  position: absolute;
  top: 30px;
  height: 40px;
  width: 40px;
  background-color: #f6f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  border-radius: 50%;
  z-index: 10;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.5s;
}

.progress_bar li::after {
  content: '';
  position: absolute;
  height: 3px;
  width: 120px;
  background-color: #f6f6f6;
  top: 48px;
  right: 37px;
  cursor: pointer;
  transition: all 0.5s;
}

.progress_bar li:nth-child(1)::after {
  display: none;
}

.progress_bar li.active::before {
  background-color: #005aa4 !important;
  color: #f6f6f6 !important;
}

.progress_bar li.active::after {
  background-color: #005aa4 !important;
}
</style>
